.Acti {
    background-image: url('../../public/Nature\ Stay1.jpg');
    background-color: black;
    background-size: cover;
    background-position: center;
    height: 70vh;
    color: white;
    position: relative;
  }
  
  .Acti::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.457);
  }

  .activities-page {
    padding: 20px;
    background-color: #f4f4f4;
    
  }
  
.activities-page h1{
    text-align: center;
    color:  orangered;
   
}
  .activity:hover {
    transform: translateY(-5px);
  }
  
  .activity h2 {
    margin: 0 0 10px;
    font-size: 24px;
  }
  
  .activity p {
    margin: 0;
    font-size: 16px;
    color: #555;
  }
  
  .ActCon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 80%;
  }
  
  .activity {
    display: flex;
    color: orangered;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
   background-color: white;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;

  }
  
  .activity-image {
    width: 100%;
    height: 60%; /* Adjust as needed */
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transition: transform 0.3s ease-in-out;
  }
  
  .activity-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .activity h2 {
    margin: 0;
    font-size: 30px; /* Adjust as needed */

  }
  
  .activity p {
    margin: 10px 0 0;
    font-size: 14px; /* Adjust as needed */
  }
  
  @media (min-width: 900px) {
    .activity {
      flex-direction: row;
      text-align: left;
      max-width: 90%;
      height: auto;
    }
  
    .activity-image {
      width: 40%;
      height: 100%;
      border-radius: 8px 0 0 8px;
    }
  
    .activity-content {
      padding: 20px;
    }
  }
  