.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .col {
    flex: 1;
    margin-bottom: 20px;
    text-align: center; 
  }
  
  .col h3 {
    font-size: 18px;
    margin-bottom: 15px;
    color: orange;
  }
  
  .col p {
    margin: 0;
    font-size: 14px; 
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 14px; 
  }
  
  .social-icons {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .social-icons li {
    margin: 0 5px; 
  }
  
  .social-icons li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
   img{
    height: 100px;
    width: 100px;
   }
