.cardWrapRoom{

    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    border-radius: 10px;


}

.banner {
    background-color: grey;
    height: 400px;
    width: 100%;
    border-radius: 20px;
    position: relative;
}

.banner img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    
    position: absolute;

}

.info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;



}

.info button {
    color: white;
    background-color: orangered;
    border: none;
    font-weight: bold;
    
  
    cursor: pointer;
}

.info p {
    color: white;
    font-weight: bold;
    font-size: 3rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;


}

a div .fa-icon {
    font-size: 30px;
    color: white;


}

@media (max-width: 768px) {
    .info {
        width: 80%;
    }

    .banner {
        background-color: grey;
        height: 400px;
        width: 100%;
        border-radius: 20px;
        position: relative;
    }

    .banner img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      
        position: absolute;

    }

    .fa-icon {
        font-size: 20px;
        color: white;


    }

    .info button {
        
        font-size: 12px;
        border-radius: 10px;
    }





    .info p {
        color: white;
        font-weight: bold;
        font-size: 1.2rem;
    }


}