
.header-image {
    position: relative;
    text-align: center;
    color: white;
    background-image: url('../../public/header.jpg');
    background-color: black;
    background-size: cover;
    background-position: center;
    height: 70vh;
   
}
.header-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}



.header-image .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.header-image h1 {
    font-size: 3rem;
    margin: 0;
}


.section {
    text-align: center;
    margin: 40px 0;
    background-color: orangered;
    padding: 20px;
    border-radius: 8px;
    color: white;
}

.contact-info {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    text-align: center;
}

.contact-info p {
    margin: 0;
    font-size: 1.2rem;
}

.contact-info a {
    display: block;
    color: #007bff;
    text-decoration: none;
    margin-top: 10px;
}


.contact-info a .fa-icon {
    margin-right: 10px;
    font-size: 20px;
}
.room-container{
    padding: 10px;
}

.room-section {
    text-align: center;
    margin: 40px 0;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding-top: 5px;
    padding-bottom: 30px;
    border-radius: 8px;
}

.room-section h2 {
    font-size: 2rem;
    color: orangered;
    margin-bottom: 20px;
    font-weight: 800;
}

.room-section img {
    width: 30%;
    border-radius: 8px;
    height: max-content;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
    margin: 10px;
}

.room-section img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Black shade */
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.room-section img:hover::after {
    opacity: 1;
}

.room-section img:hover {
    transform: scale(1.03);
}


@media (max-width: 768px) {
    .header-image h1 {
        font-size: 2rem;
    }

    .header-image .book-now {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    .room-section img {
        width: 80%;
        margin: 10px 0;
    }
}