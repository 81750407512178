.landing {
    background-image: url('../asset/Tree_scene.jpg');
    background-color: black;
    background-size: cover;
    background-position: center;
    height: 100vh;
    color: white;
    position: relative;
}
.landing::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here */
}
.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
 
}


.landing .content h1{
    color: white;
    font-size: 3rem;
}
.landing .content p{
    font-size: 1.5rem;
}
.content {
    animation-name: slideUp;
    animation-duration: 1s; /* Adjust duration as needed */
    animation-timing-function: ease; /* Adjust timing function as needed */
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translate(-50%,-30%);
    }
    to {
      opacity: 1;
      transform: translate(-50%,-50%);
    }
  }
  