/* ContactUs.css */

.con {
    background-image: url('../asset/image.png');
    background-color: black;
    background-size: cover;
    background-position: center;
    height: 70vh;
    color: white;
    position: relative;

}
.con::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.contact-container {
    padding: 20px;
    background-color: #f4f4f4;
    padding: 20px 15% 40px 15%;
}

.contact-form-container,
.contact-info-container {
    padding: 20px;
    border-radius: 8px;
    background-color: #fafafa !important; /* Light gray background for better contrast */
    margin-top: 20px;
    border-radius: 10px !important;
}
.infor{
    border-bottom: 3px solid orangered;
    text-align: center;

}
.contact-info-container h2{
    margin-bottom: 30px;
    padding-bottom: 20px;
}
.contact-form-container h2,
.contact-info-container h2 {
    margin-top: 0;
    text-align: center;
    color: #333;
   
}
.contact-info-container{
    display: flex;
    flex-direction: column;
    color: #333;
    padding: 40px;
   border: 1px solid white ;
   
}
.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form .MuiTextField-root,
.contact-form .MuiButton-root {
    margin-bottom: 15px;
}



.contact-info-container .MuiSvgIcon-root {
    color: #ff5722; 
    margin-right: 10px;
}
.body1{
    text-align: left;
}
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
    }
}
